import axios from "axios";
import ApiConfig from "../ApiConfig";
import CryptoJS from "crypto-js";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  const authToken = window.sessionStorage.getItem("authToken");
  if (!authToken) {
    throw new Error("Auth token is missing or not set in sessionStorage.");
  }
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        authToken ,
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
      console.error("Error in postAPIHandler pakda gya:", error.message);
      return error.response || error;
  }
};

export const postAPIHandlerWithoutToken = async ({ endPoint, dataToSend }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: dataToSend ? dataToSend : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const putAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      params: paramsData ? paramsData : null,
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAPIHandlerWithToken = async ({ endPoint, query, data }) => {
  try {
    const res = await axios({
      method: "GET",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      params: query,
      data: data,
    });

    if (res?.data?.responseCode === 200) {
      return res.data;
    } else {
      console.log(res?.data?.message);
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error;
    }
  }
};

export const patchAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const encrypt = (text, key) => {
  if (typeof key !== "string") {
    throw new Error("Encryption key must be a string");
  }

  const paddedKey = CryptoJS.enc.Utf8.parse(
    key.padEnd(32, "\0").substring(0, 32)
  );

  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(text, paddedKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return (
    iv.toString(CryptoJS.enc.Hex) +
    ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
};

export const decrypt = (text, key) => {
  if (typeof key !== "string") {
    throw new Error("Decryption key must be a string");
  }

  const paddedKey = CryptoJS.enc.Utf8.parse(
    key.padEnd(32, "\0").substring(0, 32)
  );
  const parts = text.split(":");
  const iv = CryptoJS.enc.Hex.parse(parts.shift());
  const encryptedText = CryptoJS.enc.Hex.parse(parts.join(":"));

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    paddedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};
