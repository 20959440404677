import { lazy } from "react";
import AuthLayout from "./layouts/AuthLayout";
import DashboardLayout from "./layouts/DashBoardLayout/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: AuthLayout,
    component: lazy(() => import("./views/pages/Authflow/Login.js")),
  },
  {
    exact: true,
    path: "/forgot",
    layout: AuthLayout,
    component: lazy(() => import("./views/pages/Authflow/ForgotPassword.js")),
  },
  {
    exact: true,
    path: "/otpVerification",
    layout: AuthLayout,
    component: lazy(() => import("./views/pages/Authflow/OTPVerification.js")),
  },
  {
    exact: true,
    path: "/resetPassword",
    layout: AuthLayout,
    component: lazy(() => import("./views/pages/Authflow/ResetPassword.js")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/MainDashboard/Dashboard.js")
    ),
  },
  {
    exact: true,
    path: "/subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubAdminManagement/SubAdminListing.js")
    ),
  },
  {
    exact: true,
    path: "/subadmin/addsubAdmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubAdminManagement/AddSubAdmin.js")
    ),
  },
  {
    exact: true,
    path: "/subadmin/editsubAdmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubAdminManagement/EditSubAdmin.js")
    ),
  },
  {
    exact: true,
    path: "/subadmin/viewsubAdmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubAdminManagement/ViewSubAdmin.js")
    ),
  },
  {
    exact: true,
    path: "/useroverview",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/UserManagement/UserOverview.js")
    ),
  },
  {
    exact: true,
    path: "/useroverview/generalview",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/UserManagement/UserDetails/GeneralViewDetails.js")
    ),
  },
  {
    exact: true,
    path: "/useroverview/generalview/runningloandetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/UserManagement/UserDetails/Loans/RunningLoanDetails.js")
    ),
  },
  {
    exact: true,
    path: "/kyclisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/KYCManagement/KYCListing.js")
    ),
  },
  {
    exact: true,
    path: "/kyclisting/viewkycdetails",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/KYCManagement/KYCDetails.js")
    ),
  },
  {
    exact: true,
    path: "/liquidityoverview",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/LiquidityManagement/LiquidityOverview.js")
    ),
  },
  {
    exact: true,
    path: "/cryptoexchangelisting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/CryptoExchangeManagement/CryptoexchangeListing.js")
    ),
  },

  {
    exact: true,
    path: "/overview",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/Overview.js")),
  },

  {
    exact: true,
    path: "/overview/manageFdInterest",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/FD/FDInterestManagement.js")),
  },
  {
    exact: true,
    path: "/overview/manageRdInterest",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/RD/RDInterestManagement.js")),
  },
  {
    exact: true,
    path: "/overview/manageMisddInterest",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/MISD/MISDInterestManagement.js")),
  },
  {
    exact: true,
    path: "/loanlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/LoanManagement/LoanListing.js")),
  },
  {
    exact: true,
    path: "/loanlisting/editloan",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/LoanManagement/EditLoan.js")),
  },
  {
    exact: true,
    path: "/loanlisting/viewloanplan",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/LoanManagement/ViewLoanPlan/ViewLoanPlan.js")),
  },
  {
    exact: true,
    path: "/loanlisting/viewloanplan/userrequestedloan",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/LoanManagement/ViewLoanPlan/Tabs/UserListing/UserRequestedLoan.js")),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ManageProfile.js")),
  },
  {
    exact: true,
    path: "/profile/editprofile",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/EditProfile.js")),
  },
  {
    exact: true,
    path: "/profile/changepassword",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Setting/ChangePassword.js")),
  },
  {
    exact: true,
    path: "/contentlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/StaticContentManagement.js")),
  },
  {
    exact: true,
    path: "/contentlisting/privacypolicy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/PrivacyPolicy.js")),
  },
  {
    exact: true,
    path: "/contentlisting/editprivacypolicy",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/PrivacyPolicy/EditPrivacyPolicy.js")),
  },
  {
    exact: true,
    path: "/contentlisting/aboutus",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/AboutUs/Aboutus.js")),
  },
  {
    exact: true,
    path: "/contentlisting/editaboutus",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/AboutUs/EditAboutUs.js")),
  },
  {
    exact: true,
    path: "/contentlisting/tc",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/Terms&Conditions/Terms&Conditions.js")),
  },
  {
    exact: true,
    path: "/contentlisting/edittc",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/Terms&Conditions/EditTerms&Conditions.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQs/FAQs.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq/addfaq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQs/addFAQ.js")),
  },
  {
    exact: true,
    path: "/contentlisting/faq/editfaq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/FAQs/editFAQ.js")),
  },
  {
    exact: true,
    path: "/ticketlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/TicketManagement/TicketListing.js")),
  },
  {
    exact: true,
    path: "/ticketlisting/viewticket",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/TicketManagement/ViewTicket.js")),
  },

  {
    exact: true,
    path: "/feedbacklisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/FeedbackManagement/FeedbackListing.js")),
  },
  {
    exact: true,
    path: "/feedbacklisting/viewfeedback",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/FeedbackManagement/ViewFeedback.js")),
  },
  {
    exact: true,
    path: "/contentlisting/bannerlisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BannerManagement/BannerListing.js")),
  },
  {
    exact: true,
    path: "/contentlisting/bannerlisting/addEditBanner",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BannerManagement/AddEditBanner.js")),
  },

  {
    exact: true,
    path: "/contentlisting/bloglisting",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BlogManagement/Index.js")),
  },

  {
    exact: true,
    path: "/contentlisting/bloglisting/addeditcategory",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BlogManagement/Tabs//BlogCategory/AddEditCategory.js")),
  },

  {
    exact: true,
    path: "/contentlisting/bloglisting/addeditblog",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BlogManagement/Tabs/BlogListing/AddEditBlog.js")),
  },
  {
    exact: true,
    path: "/contentlisting/bloglisting/viewblog",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BlogManagement/Tabs/BlogListing/ViewBlog.js")),
  },
  {
    exact: true,
    path: "",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/StaticContentManagement/BlogManagement/Tabs/BlogListing/ViewBlog.js")),
  },
  {
    exact: true,
    path: "/overview/manageFdInterest/viewfddetails",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/FD/ViewFDDetails.js")),
  },
  {
    exact: true,
    path: "/overview/manageFdInterest/viewrddetails",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/RD/ViewRDDetails.js")),
  },
  {
    exact: true,
    path: "/overview/manageFdInterest/viewmisddetails",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/InvestmentManagement/MISD/ViewMISDDetails.js")),
  },

];
