import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  FormHelperText,
} from "@mui/material";
import { Form, Formik } from "formik";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogCommon from "../../../components/CommonDialogs";
import { loginformValidationSchema } from "../../../schema";
import {
  encrypt,
  decrypt,
  postAPIHandlerWithoutToken,
} from "../../../config/service";

const LoginMainBox = styled(Box)({
  height: "97.5%",
  position: "relative",
  zIndex: "999",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflowY: "auto",
});

const LoginBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  height: "initial",
  margin: "15px auto",
  maxWidth: "95%",
  width: "487px",
  maxHeight: "100%",
  "& .mainBox": {
    background: "#fff",
    padding: "50px 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 10px 50px",
    },
    "& h2": {
      textAlign: "center",
      paddingBottom: "25px",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "10px",
      },
    },
  },
  "& .displaySpacebetween": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "15px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
  },
}));

const IconsClass1 = styled(Box)({
  color: "#585757",
  fontSize: "20px",
});

const HelperText = styled(FormHelperText)({
  marginTop: "8px",
});

const CheckboxAll = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    padding: "9px 6px 9px 0px",
    color: "#2e6cd7",
  },
});

const ForgetPassBtnLogin = styled(Typography)({
  color: "#2e6cd7",
  cursor: "pointer",
});

const DisplayStart = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const Login = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [showPassword, setShowPassword] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(
    localStorage.getItem("password") != null
  );
  const navigate = useNavigate();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const formInitialSchema = {
    email: localStorage.getItem("email") || "",
    password: localStorage.getItem("password") || "",
  };

  const handleFormSubmit = async (values) => {
    try {
      const salt = process.env.REACT_APP_SECURE_SALT;
      if (!salt) {
        throw new Error(
          "Encryption key is not defined in environment variables"
        );
      }
      const requestData = {
        email: values.email,
        password: values.password,
      };

      const encryptedData = await encrypt(JSON.stringify(requestData), salt);

      const response = await postAPIHandlerWithoutToken({
        endPoint: "adminLogin",
        dataToSend: { request: encryptedData },
      });
      if (response) {
        const decryptedData = await decrypt(response.data.response, salt);
        const parsedData = JSON.parse(decryptedData);
        console.log(parsedData, "parsedData");
        if (response?.data?.responseCode === 200) {
          sessionStorage.setItem("authToken", parsedData.authToken);
          if (rememberMe) {
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
          navigate("/dashboard");
        } else {
          setOpenDialog(true);
        }
      } else {
        setOpenDialog(true);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setOpenDialog(true);
    }
  };

  return (
    <LoginMainBox>
      <LoginBox>
        <Paper
          className="mainBox"
          elevation={5}
          sx={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Custom shadow
          }}
        >
          <Typography
            sx={{ textAlign: "center", color: "#2e6cd7" }}
            variant="h3"
            color="primary"
          >
            Admin Login
          </Typography>
          <Formik
            initialValues={formInitialSchema}
            validationSchema={loginformValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form>
                <Box>
                  <Box mt={2} mb={1}>
                    <Typography variant="body2">Email:</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    placeholder="Enter email address"
                    name="email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <HelperText error>{touched.email && errors.email}</HelperText>
                </Box>
                <Box>
                  <Box mt={2} mb={1}>
                    <Typography variant="body2">Password:</Typography>
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={values.password}
                    error={Boolean(touched.password && errors.password)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <IconsClass1>
                              {showPassword ? <HiEye /> : <HiEyeOff />}
                            </IconsClass1>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <HelperText error>
                    {touched.password && errors.password}
                  </HelperText>
                </Box>
                <Box className="displaySpacebetween">
                  <DisplayStart>
                    <CheckboxAll
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <Typography variant="body2">Remember Me</Typography>
                  </DisplayStart>
                  <ForgetPassBtnLogin
                    variant="body2"
                    onClick={() => navigate("/forgot")}
                  >
                    Forgot password ?
                  </ForgetPassBtnLogin>
                </Box>
                <Box mt={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Login
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>

        <DialogCommon
          handleClose={handleClose}
          open={openDialog}
          type="loginAuthCredentialsNotMatch"
        />
      </LoginBox>
    </LoginMainBox>
  );
};

export default Login;
