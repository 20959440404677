import React, { Suspense, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Updated import
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"; // Updated import
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Updated import
import { routes } from "./routes";
import AuthContext from "./context/Auth";
import PageLoading from "./components/PageLoading";
import { createMuiTheme } from "./theme";
import AuthGuard from "./config/AuthGuard";
import { ThemeProvider, createTheme } from "@mui/material/styles"; // Updated import
import { Toaster } from "react-hot-toast";
// import "./scss/main.css";
import Login from "./views/pages/Authflow/Login"

function App() {
  const theme = createMuiTheme({ theme: 'light', direction: 'ltr' });  // Updated function
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthContext>
            <Router>
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}



export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
    <Routes>
      {props.data.map((route, i) => {
        const Component = route.component;
        const Guard = route.guard ? AuthGuard : Fragment;
        const Layout = route.layout || Fragment;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Toaster position="top-right" />
                <Layout>
                  {route.routes ? (
                    <RenderRoutes data={route.routes} />
                  ) : (
                    <Component />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
    </Suspense>
  );
}
