import React from "react";
import { styled } from "@mui/system";
import LoadingImg from "../assets/MainLogo.svg";

// Define the spin keyframes using the keyframes helper
const spin = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Styled component for the root container
const Root = styled('div')({
  alignItems: "center",
  backgroundColor: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center",
  zIndex: 2000,
  position: "relative",
});

// Styled component for the loader wrapper
const LoaderWrapper = styled('div')({
  position: 'relative',
});

// Styled component for the spinning loader overlay
const LoaderOverlay = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '50%',
  // border: '5px solid #ffffff', /* Light grey */
  // borderTop: '5px solid #f3f3f3', /* Blue */
  width: 100,
  height: 100,
  animation: 'spin linear infinite',
  ...spin,
});

// Styled component for the loader image
const Loader = styled('img')({
  width: 140,
  height: 140,
  borderRadius: '50%',
  backgroundColor: '#2e6cd7', // Set background color here
  padding: '10px', // Optional padding
});

export default function PageLoading() {
  return (
    <Root>
      <LoaderWrapper>
        <LoaderOverlay />
        <Loader src={LoadingImg} alt="loader" />
      </LoaderWrapper>
    </Root>
  );
}
