export default {
    h1: {
      fontWeight: 500,
      fontSize: 45,
      lineHeight: "52px",
      color: "#0C111D",
      fontFamily: "Lexend",
      "@media (max-width:767px)": {
        fontSize: "30px !important",
        lineHeight: "40px",
      },
    },
    h2: {
      fontWeight: 400,
      fontSize: 40,
      lineHeight: "45px",
      fontFamily: "Lexend",
      color: "#0C111D",
      wordBreak: "break-word",
      "@media (max-width:767px)": {
        fontSize: "25px !important",
        lineHeight: "30px !important",
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 28,
      lineHeight: "40px",
      color: "#0C111D",
      fontFamily: "Lexend",
      "@media (max-width: 1024px)": {
        fontSize: "26px", // for tablets and medium devices
      },
      "@media (max-width: 768px)": {
        fontSize: "22px", // for small devices like tablets in portrait mode
      },
      "@media (max-width: 480px)": {
        fontSize: "20px", // for mobile phones
      },
      "@media (max-width: 320px)": {
        fontSize: "18px", // for very small screens
      },
    },
    
    h4: {
      fontWeight: 600,
      color: "rgba(0, 0, 0, 0.87)",

      fontSize: 25,
      lineHeight: "30px",
      fontFamily: "Lexend",
      "@media (max-width:767px)": {
        fontSize: "18px !important",
      },
    },
    h5: {
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 600,
      fontSize: 20,
      
      fontFamily: 'Lexend',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '@media (max-width: 576px)': {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    h6: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "24px",
      color: "#0C111D",
      fontFamily: "Lexend",
      "@media (max-width:767px)": {
        lineHeight: "22px",
        fontSize: "14px !important",
      },
    },
    overline: {
      fontWeight: 500,
      fontFamily: 'Lexend',
    },
    
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "20px",
      fontFamily: "Lexend",
      color: "#0C111D",
      "@media (max-width:767px)": {
        fontSize: "15px",
        lineHeight: "18px",
      },
    },
    body1: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "24px",
      color: "#262626",
      fontFamily: "Lexend",
      "@media (max-width:767px)": {
        lineHeight: "22px",
        fontSize: "14px !important",
      },
    },
  
    // ...... Project Custom Typography ....... //
    
    mainHeading: {
      fontWeight: 600,
      fontSize: 24,
      color: "#080515",
      fontFamily: "Lexend",
      "@media(maxWidth:767px)": {
        fontSize: "18px !important",
      },
    },
    button: {
      fontWeight: '400',
      fontSize: '15px',
      fontFamily: "Lexend",
    },
    label: {
      fontWeight: 600,
      fontSize: 16,
      color: "#080515",
      fontFamily: "Lexend",
      "@media(maxWidth:767px)": {
          fontSize: "8px !important",
      },
    },
    detail: {
      fontWeight: 400,
      fontSize: 16,
      color: "black",
      fontFamily: "Lexend",
      "@media(maxWidth:767px)": {
          fontSize: "8px !important",
      },
    },
    
    tableCell: {
    fontWeight: 500,
    fontSize: 14,
    color: "#080515",
    fontFamily: "Lexend",
    textAlign: "center",
    "@media(maxWidth:767px)": {
      fontSize: "8px !important",
    },
    "&:hover": {
      backgroundColor: "#b9cef1", // Change this to your desired hover background color
      color: "white", // Change this to your desired hover font color
    },
    "&:focus": {
      backgroundColor: "#2e6cd7", // Change this to your desired focus background color
      color: "white", // Change this to your desired focus font color
    },
    },
    subtitle1: {
     
      color: "#0C111D",
     
     
    },
  
    
  };
  