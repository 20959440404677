import React, { useState } from "react";

import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { styled } from "@mui/system";
import { useFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { addSubscriptionSchema, addEditTenureSchema } from "../schema";

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "white !important",
  },
}));

const TextAreaStyled = styled(TextareaAutosize)(({ theme }) => ({
  background: "white",
  border: "1px solid grey",
  borderRadius: "10px",
  width: "100%",
  resize: "none",
  marginTop: "5px",
  padding: "18.5px 14px",
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: "white",
  border: "1px solid black",
}));

export default function DialogCommon({
  handleClose,
  handleCloseSuccess,
  open,
  type,
  handleCloseResend,
  item,
  blockStatus,
  handleDeleteConfirm,
  handleBlockConfirm,
  solution, setSolution, handleReply, maxWords = 250, 
  handleAddSubscriptionConfirm,
  handleEditSubscriptionConfirm,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState("");
  const [duration, setDuration] = useState({
    years: "1",
    months: "2",
  });

  const handleDurationChange = (e) => {
    const { name, value } = e.target;
    setDuration((prevDuration) => ({
      ...prevDuration,
      [name]: value,
    }));
  };

  const getFormattedDuration = () => {
    const { years, months } = duration;
    let formattedDuration = "";

    if (years) {
      formattedDuration += `${years} Year${years > 1 ? "s" : ""}`;
    }
    if (months) {
      if (formattedDuration) formattedDuration += " ";
      formattedDuration += `${months} Month${months > 1 ? "s" : ""}`;
    }

    return formattedDuration || "Select Duration";
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const initialValuesTenure = {
    minAmount: "",
    maxAmount: "",
    durationYears: "",
    durationMonths: "",
    interestRate: "",
    prematureInterestRate: "",
    compounding: "No",
    compoundingPeriod: "",
  };

  const initialValues = {
    subscriptionName: "",
    jobPostings: "",
    currency: "",
    amount: "",
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addSubscriptionSchema,
      onSubmit: (values) => {
        // handleSubmit;
        // handleAddSubscriptionConfirm
      },
    });

  const handleSubmitTenure = async (values) => {
    // Handle form submission logic for Form2
    console.log("tenure Submitted:", values);
    handleClose();
  };

  const handleServiceFees = async (values) => {
    console.log("service fees updated", values);
    handleClose();
  };
  

  // Function to handle textarea changes
  const handleChange1 = (event) => {
    const text = event.target.value;
    // Count the number of words
    const wordCount = text.split(/\s+/).filter(Boolean).length;
    if (wordCount <= maxWords) {
      setSolution(text);
    }
  };

  // const handleReply = () => {
  //   console.log("Reply:", solution);
  //   handleClose();
  // };

  return (
    <Box>
      {type === "loginAuthCredentialsNotMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                Invalid
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                User Not Found. Please Check & Try Again.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "resetPasswordSuccessDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                Success
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                New password has been reset successfully.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "ForgotAuthCredentialsNotMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                Invalid
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Entered email is not found. Please check.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "ForgotSuccessAuthCredentialsMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                SUCCESS
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                OTP sent to your entered email address.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleCloseSuccess}
              autoFocus
              color="secondary"
            >
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "OtpAuthCredentialsMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                SUCCESS
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                OTP verification is successful.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "OtpResendDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="secondary">
                SUCCESS
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                OTP resend to your entered email address.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleCloseResend}
              autoFocus
              color="secondary"
            >
              OK
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "deleteDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="red" fontWeight={800}>
                Delete
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you want to delete this {item} ?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleDeleteConfirm}
              autoFocus
              color="secondary"
            >
              Delete
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "subAdminDelete" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="red" fontWeight={800}>
                Delete
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you want to delete this {item} ?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Delete
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "subAdminBlockUnblock" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="primary" fontWeight={800}>
                Block/Unblock
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you want to Block/UnBlock ?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              YES
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              NO
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "rejectDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" fontWeight={800}>
                Reject Recruiter
              </Typography>
            </Box>
            <Box
              align="center"
              my={3}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography variant="h6" color="secondary" align="left">
                Enter Reason For Rejection
              </Typography>
              <textarea type="text" rows={10} width={"100%"} />
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleCloseResend}
              autoFocus
              color="secondary"
            >
              Reject
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "blockUnblockDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" fontWeight={800}>
                {blockStatus === "Active" ? "Block" : "Unblock"}
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you sure you want to{" "}
                {blockStatus === "Active" ? "block" : "unblock"} this {item}?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleBlockConfirm}
              autoFocus
              color="secondary"
            >
              {blockStatus === "Active" ? "Block" : "Unblock"}
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}

      {type === "addSubscriptionDialog" && (
        <form onSubmit={handleSubmit}>
          <DialogStyled
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
          >
            <DialogContent>
              <Box align="center">
                <Typography variant="h3" fontWeight={800}>
                  Add Subscription Plan
                </Typography>
              </Box>
              <Box my={3} display={"flex"} flexDirection={"column"}>
                <Typography variant="h6" color="secondary" align="left">
                  Name:
                </Typography>
                <TextField
                  type="text"
                  name="subscriptionName"
                  value={values.subscriptionName}
                  maxLength={30}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  width={"100%"}
                  placeholder="Enter subscription name"
                />
                {errors.subscriptionName && touched.subscriptionName && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.subscriptionName}
                  </p>
                )}
              </Box>
              <Box my={3} display={"flex"} flexDirection={"column"}>
                <Typography variant="h6" color="secondary" align="left">
                  Allowed Job Postings:
                </Typography>
                <TextField
                  type="text"
                  width={"100%"}
                  placeholder="Enter allowed job postings"
                  name="jobPostings"
                  value={values.jobPostings}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.jobPostings && touched.jobPostings && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.jobPostings}
                  </p>
                )}
              </Box>
              <Box my={3} display={"flex"} flexDirection={"column"}>
                <Typography variant="h6" color="secondary" align="left">
                  Currency:
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="currency-label"
                    value={currency}
                    onChange={handleCurrencyChange}
                    displayEmpty
                    renderValue={
                      currency !== ""
                        ? undefined
                        : () => (
                            <span
                              style={{ color: "gray", fontFamily: "Lexend" }}
                            >
                              Select currency
                            </span>
                          )
                    }
                    sx={{
                      "& .MuiSelect-select": {
                        fontFamily: "Lexend", // Custom font for selected item
                        color: "Black", // Custom color for selected item
                      },
                    }}
                  >
                    {errors.amount && touched.amount && (
                      <p
                        style={{
                          color: "red",
                          marginTop: "2px",
                          float: "left",
                        }}
                      >
                        {errors.amount}
                      </p>
                    )}
                    <MenuItem
                      value={"USD"}
                      sx={{ fontFamily: "Lexend", color: "Black" }}
                    >
                      USD
                    </MenuItem>
                    <MenuItem
                      value={"EUR"}
                      sx={{ fontFamily: "Lexend", color: "Black" }}
                    >
                      EUR
                    </MenuItem>
                    <MenuItem
                      value={"GBP"}
                      sx={{ fontFamily: "Lexend", color: "Black" }}
                    >
                      GBP
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box my={3} display={"flex"} flexDirection={"column"}>
                <Typography variant="h6" color="secondary" align="left">
                  Amount:
                </Typography>
                <TextField
                  type="number"
                  width={"100%"}
                  placeholder="Enter amount"
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.amount && touched.amount && (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.amount}
                  </p>
                )}
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <ButtonStyled
                type="submit"
                name="submit"
                autoFocus
                color="secondary"
              >
                Create Plan
              </ButtonStyled>
              <ButtonStyled onClick={handleClose} autoFocus color="secondary">
                Cancel
              </ButtonStyled>
            </DialogActions>
          </DialogStyled>
        </form>
      )}

      {type === "editSubscriptionDialog" && (
        <Formik
          initialValues={initialValuesTenure}
          validationSchema={addEditTenureSchema}
          onSubmit={handleSubmitTenure}
        >
          {() => (
            <form onSubmit={handleSubmit}>
              <DialogStyled
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xs"
              >
                <DialogContent>
                  <Box align="center">
                    <Typography variant="h3" fontWeight={800}>
                      Edit Subscription Plan
                    </Typography>
                  </Box>
                  <Box my={3} display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" color="secondary" align="left">
                      Name:
                    </Typography>
                    <TextField
                      type="text"
                      name="subscriptionName"
                      value={values.subscriptionName}
                      maxLength={30}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      width={"100%"}
                      placeholder="Enter subscription name"
                    />
                    {errors.subscriptionName && touched.subscriptionName && (
                      <p
                        style={{
                          color: "red",
                          marginTop: "2px",
                          float: "left",
                        }}
                      >
                        {errors.subscriptionName}
                      </p>
                    )}
                  </Box>
                  <Box my={3} display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" color="secondary" align="left">
                      Allowed Job Postings:
                    </Typography>
                    <TextField
                      type="text"
                      width={"100%"}
                      placeholder="Enter allowed job postings"
                      name="jobPostings"
                      value={values.jobPostings}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.jobPostings && touched.jobPostings && (
                      <p
                        style={{
                          color: "red",
                          marginTop: "2px",
                          float: "left",
                        }}
                      >
                        {errors.jobPostings}
                      </p>
                    )}
                  </Box>
                  <Box my={3} display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" color="secondary" align="left">
                      Currency:
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="currency-label"
                        value={currency}
                        onChange={handleCurrencyChange}
                        displayEmpty
                        renderValue={
                          currency !== ""
                            ? undefined
                            : () => (
                                <span
                                  style={{
                                    color: "gray",
                                    fontFamily: "Lexend",
                                  }}
                                >
                                  Select currency
                                </span>
                              )
                        }
                        sx={{
                          "& .MuiSelect-select": {
                            fontFamily: "Lexend", // Custom font for selected item
                            color: "Black", // Custom color for selected item
                          },
                        }}
                      >
                        {errors.amount && touched.amount && (
                          <p
                            style={{
                              color: "red",
                              marginTop: "2px",
                              float: "left",
                            }}
                          >
                            {errors.amount}
                          </p>
                        )}
                        <MenuItem
                          value={"USD"}
                          sx={{ fontFamily: "Lexend", color: "Black" }}
                        >
                          USD
                        </MenuItem>
                        <MenuItem
                          value={"EUR"}
                          sx={{ fontFamily: "Lexend", color: "Black" }}
                        >
                          EUR
                        </MenuItem>
                        <MenuItem
                          value={"GBP"}
                          sx={{ fontFamily: "Lexend", color: "Black" }}
                        >
                          GBP
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box my={3} display={"flex"} flexDirection={"column"}>
                    <Typography variant="h6" color="secondary" align="left">
                      Amount:
                    </Typography>
                    <TextField
                      type="number"
                      width={"100%"}
                      placeholder="Enter amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.amount && touched.amount && (
                      <p
                        style={{
                          color: "red",
                          marginTop: "2px",
                          float: "left",
                        }}
                      >
                        {errors.amount}
                      </p>
                    )}
                  </Box>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <ButtonStyled
                    type="submit"
                    name="submit"
                    autoFocus
                    color="secondary"
                  >
                    Update Plan
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={handleClose}
                    autoFocus
                    color="secondary"
                  >
                    Cancel
                  </ButtonStyled>
                </DialogActions>
              </DialogStyled>
            </form>
          )}
        </Formik>
      )}

      {type === "addedittenure" && (
        <Formik
          initialValues={initialValuesTenure}
          validationSchema={addEditTenureSchema}
          onSubmit={handleSubmitTenure}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogContent>
                  <Box textAlign="center" mb={3}>
                    <Typography variant="h3" fontWeight="bold">
                      Add New Amount Range
                    </Typography>
                  </Box>

                  {/* Min & Max Amount Fields */}
                  <Box display="flex" justifyContent="space-between" mb={3}>
                    <Box width="48%">
                      <Typography variant="body1" fontWeight="bold" mb={1}>
                        Min. Amount
                      </Typography>
                      <Field
                        name="minAmount"
                        as={TextField}
                        type="number"
                        fullWidth
                        placeholder="Enter min amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.minAmount}
                        error={!!(errors.minAmount && touched.minAmount)}
                      />
                      <Typography
                        color="red"
                        variant="body2"
                        style={{ marginTop: "4px" }}
                      >
                        {" "}
                        <ErrorMessage name="minAmount" />
                      </Typography>
                    </Box>
                    <Box width="48%">
                      <Typography variant="body1" fontWeight="bold" mb={1}>
                        Max. Amount
                      </Typography>
                      <Field
                        name="maxAmount"
                        as={TextField}
                        type="number"
                        fullWidth
                        placeholder="Enter max amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.maxAmount}
                        error={!!(errors.maxAmount && touched.maxAmount)}
                      />
                      <Typography
                        color="red"
                        variant="body2"
                        style={{ marginTop: "4px" }}
                      >
                        {" "}
                        <ErrorMessage name="maxAmount" />
                      </Typography>
                    </Box>
                  </Box>

                  {/* Duration Field */}
                  <Typography variant="body1" fontWeight="bold" mb={1}>
                    Duration
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {getFormattedDuration()}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" mb={3}>
                    <FormControl fullWidth style={{ marginRight: "16px" }}>
                      <Field
                        as={Select}
                        name="durationYears"
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.durationYears}
                        error={
                          !!(errors.durationYears && touched.durationYears)
                        }
                      >
                        <MenuItem value="" disabled>
                          Year
                        </MenuItem>
                        <MenuItem value="1">1 Year</MenuItem>
                        <MenuItem value="2">2 Years</MenuItem>
                        <MenuItem value="3">3 Years</MenuItem>
                      </Field>
                      <Typography
                        color="red"
                        variant="body2"
                        style={{ marginTop: "4px" }}
                      >
                        {" "}
                        <ErrorMessage name="durationYears" />
                      </Typography>
                    </FormControl>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        name="durationMonths"
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.durationMonths}
                        error={
                          !!(errors.durationMonths && touched.durationMonths)
                        }
                      >
                        <MenuItem value="" disabled>
                          Month
                        </MenuItem>
                        <MenuItem value="1">1 Month</MenuItem>
                        <MenuItem value="2">2 Months</MenuItem>
                        <MenuItem value="3">3 Months</MenuItem>
                      </Field>
                      <Typography
                        color="red"
                        variant="body2"
                        style={{ marginTop: "4px" }}
                      >
                        {" "}
                        <ErrorMessage name="durationMonths" />
                      </Typography>
                    </FormControl>
                  </Box>

                  {/* Interest Rate Fields */}
                  <Box mb={3}>
                    <Typography variant="body1" fontWeight="bold" mb={1}>
                      Interest Rate (%)
                    </Typography>
                    <Field
                      name="interestRate"
                      as={TextField}
                      fullWidth
                      placeholder="Enter the actual interest rate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.interestRate}
                      error={!!(errors.interestRate && touched.interestRate)}
                    />
                    <Typography
                      color="red"
                      variant="body2"
                      style={{ marginTop: "4px" }}
                    >
                      {" "}
                      <ErrorMessage name="interestRate" />
                    </Typography>
                  </Box>

                  <Box mb={3}>
                    <Typography variant="body1" fontWeight="bold" mb={1}>
                      Pre-mature Withdrawal Interest Rate (%)
                    </Typography>
                    <Field
                      name="prematureInterestRate"
                      as={TextField}
                      fullWidth
                      placeholder="Enter the interest rate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prematureInterestRate}
                      error={
                        !!(
                          errors.prematureInterestRate &&
                          touched.prematureInterestRate
                        )
                      }
                    />
                    <Typography
                      color="red"
                      variant="body2"
                      style={{ marginTop: "4px" }}
                    >
                      {" "}
                      <ErrorMessage name="prematureInterestRate" />
                    </Typography>
                  </Box>

                  {/* Compounding Field */}
                  <Box mb={3}>
                    <Typography variant="body1" fontWeight="bold" mb={1}>
                      Compounding
                    </Typography>
                    <Field
                      name="compounding"
                      as={RadioGroup}
                      row
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.compounding}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </Field>
                    <Typography
                      color="red"
                      variant="body2"
                      style={{ marginTop: "4px" }}
                    >
                      {" "}
                      <ErrorMessage name="compounding" />
                    </Typography>
                  </Box>

                  {/* Compounding Period */}
                  {values.compounding === "Yes" && (
                    <Box mb={3}>
                      <Typography variant="body1" fontWeight="bold" mb={1}>
                        Compounding Period
                      </Typography>
                      <Field
                        as={Select}
                        name="compoundingPeriod"
                        displayEmpty
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.compoundingPeriod}
                        error={
                          !!(
                            errors.compoundingPeriod &&
                            touched.compoundingPeriod
                          )
                        }
                      >
                        <MenuItem value="" disabled>
                          Select Compounding Period
                        </MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="quarterly">Quarterly</MenuItem>
                        <MenuItem value="annually">Annually</MenuItem>
                      </Field>
                      <Typography
                        color="red"
                        variant="body2"
                        style={{ marginTop: "4px" }}
                      >
                        <ErrorMessage name="compoundingPeriod" />
                      </Typography>
                    </Box>
                  )}
                </DialogContent>

                <DialogActions style={{ justifyContent: "center" }}>
                  <Button
                    name="submit"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Add Tenure
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}

      {type === "manageservicefee" && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogContent>
            <Box textAlign="center" mb={3}>
              <Typography variant="h3" fontWeight="bold">
                Manage Service Fee (%)
              </Typography>
            </Box>

            <Box mb={3}>
              <TextField
                fullWidth
                placeholder="Enter the service fee"
              />
              <Typography
                color="red"
                variant="body2"
                style={{ marginTop: "4px" }}
              >
                
               
              </Typography>
            </Box>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              name="submit"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {type === "ticketreply" && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogContent>
            <Box textAlign="center" mb={3}>
              <Typography variant="h3" fontWeight="bold">
                Ticket Reply
              </Typography>
            </Box>

            {/* Min & Max Amount Fields */}
            <Box mb={3}>
              <div style={{ position: "relative" }}>
                <textarea
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    resize: "vertical", 
                                    }}
                  value={solution}
                  onChange={handleChange1}
                />
              </div>
              <div
                style={{
                  position: "absolute",

                  right: "20px",

                  fontSize: "0.75rem",
                  color: "#666",
                }}
              >
                {`${Math.max(
                  0,
                  maxWords - solution.split(/\s+/).filter(Boolean).length
                )} words remaining`}
              </div>
            </Box>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button onClick={handleReply} variant="contained" color="primary">
              Reply
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {type === "feedbackreply" && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogContent>
            <Box textAlign="center" mb={3}>
              <Typography variant="h3" fontWeight="bold">
                Feedback Reply
              </Typography>
            </Box>

            {/* Min & Max Amount Fields */}
            <Box mb={3}>
              <div style={{ position: "relative" }}>
                <textarea
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    boxSizing: "border-box",
                    resize: "vertical", 
                                    }}
                  value={solution}
                  onChange={handleChange1}
                />
              </div>
              <div
                style={{
                  position: "absolute",

                  right: "20px",

                  fontSize: "0.75rem",
                  color: "#666",
                }}
              >
                {`${Math.max(
                  0,
                  maxWords - solution.split(/\s+/).filter(Boolean).length
                )} words remaining`}
              </div>
            </Box>
          </DialogContent>

          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button onClick={handleReply} variant="contained" color="primary">
              Reply
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
