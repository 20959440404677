import * as Yup from "yup";
import "react-phone-input-2/lib/style.css";

const emailRegex =
  /^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;
const firstnameRegex = /^[a-zA-Z]*$/;
const lastRegex = /^[a-zA-Z\s]*$/;
const zipRegex = /^[A-Za-z0-9]{2,10}([- ]?[A-Za-z0-9]{2,10})?$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&^(}){|\-_+="':;,.~`])[A-Za-z\d@$!#%*?&^(}){|\-_+="':;,.~`]{8,}$/;


export const loginformValidationSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Please enter a valid email address.')
    .required('Please enter email address.'),
  password: Yup
    .string()
    .required('Please enter password.'),
});

export const forgotValidationSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Please enter a valid email address.')
    .required('Please enter email address.'),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  newPassword: Yup
    .string()
    .trim()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must be 8 to 16 characters long with one uppercase, one lowercase, a number, and a special character."
    )
    .required("Please enter new password."),
  confirmPassword: Yup
    .string()
    .required("Please enter confirm password .")
    .oneOf([Yup.ref("newPassword"), null], "New password and Confirm password must be the same."),
});

export const addSubAdminvalidationSchema = Yup.object({
  fullName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, "Full Name must contain only alphabets.")
    .max(60, "Full Name must be at most 60 characters.")
    .required("Please enter full name."),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format."
    )
    .required("Please enter email."),
  phoneNumber: Yup.string()
    .test("isValidPhoneNumber", "Invalid phone number.", function (value) {
      if (!value || !this.options.context.countryCode) return true;
      const { countryCode } = this.options.context;
      let value1;
      if (value.startsWith(countryCode)) {
        value1 = value.slice(countryCode.length);
      } else {
        return false;
      }
      return true;
    })
    .required("Please enter phone number."),
  imageUrl: Yup.string().required("Please upload a profile image."),
  permissions: Yup.object()
    .test(
      "at-least-one-checked",
      "At least one permission must be selected.",
      (value) => Object.values(value).includes(true)
    )
});

export const textDropdown = Yup.object({
  websiteLink: Yup.string()
    // .optional(),
    .required("Please enter link")
    .url("Invalid URL format"),
});

export const addBlogSchema = Yup.object({
  blogTitle: Yup.string()
    .required("Please enter the blog title.")
    .trim()
    .min(2, "Title cannot be less than 2 characters.")
    .max(64, "Title cannot be more than 64 characters."),

  blogImg: Yup.string().required("Please upload img.").trim(),

  blogDescription: Yup.string()
    .required("Please enter the blog description.")
    .trim()
    .min(10, "Description cannot be less than 10 characters.")
    .max(100, "Description cannot be more than 100 characters."),
});

export const addSubscriptionSchema = Yup.object({
  subscriptionName: Yup.string()
    .required("Please enter the subscription plan name.")
    .trim()
    .min(2, "Subscription name cannot be less than 2 characters.")
    .max(30, "Subscription name cannot be more than 30 characters."),

  jobPostings: Yup.string()
    .required("Please enter allowed job postings.")
    .trim(),
  currency: Yup.string().required("Please select currency."),
  amount: Yup.string().required("Please enter the amount.").trim(),
});

export const FAQSchema = Yup.object({
  question: Yup.string().required("Please enter the question.").trim(),

  answer: Yup.string().required("Please enter the answer.").trim(),
});

export const BannerSchema = Yup.object({
  bannerTitle: Yup.string()
    .required("Please enter the banner title.")
    .trim()
    .min(2, "Title cannot be less than 2 characters.")
    .max(100, "Title cannot be more than 100 characters."),

  bannerImg: Yup.string().required("Please upload img.").trim(),
});

export const editEmployerDetailSchema = Yup.object({
  companyName: Yup.string()
    .required("Please enter the company name.")
    .trim()
    .min(2, "Company name cannot be less than 2 characters.")
    .max(64, "Company name cannot be more than 64 characters."),

  ownerName: Yup.string()
    .required("Please enter the owner name.")
    .trim()
    .min(2, "Owner name cannot be less than 2 characters.")
    .max(64, "Owner name cannot be more than 64 characters."),

  companyTagline: Yup.string()
    .required("Please enter the company tagline name")
    .trim()
    .min(2, "Company tagline cannot be less than 2 characters.")
    .max(64, "Company tagline cannot be more than 64 characters."),

  established: Yup.string()
    .required("Please enter the established date")
    .trim(),
  // .min(4, "Cannot be less than 4 characters."),
  // .max(64, "Title cannot be more than 64 characters."),

  location: Yup.string()
    // .optional(),
    .required("Please select location"),

  logo: Yup.string().required("Please upload company logo.").trim(),

  password: Yup.string()
    .required("Please enter the password.")
    .trim()
    .min(8, "Password must be more than 8 characters.")
    // .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
    .max(20, "Password cannot be more than 20 characters."),

  confirmPassword: Yup.string()
    .required("Please enter the confirm password.")
    .trim()
    .oneOf([Yup.ref("password"), null], "Password must match."),

  gstin: Yup.string().required("Please upload GSTIN.").trim(),

  pan: Yup.string().required("Please upload company PAN."),

  iec: Yup.string().required("Please upload company IEC."),

  email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .min(2, "Email cannot be less than 2 characters.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Please enter valid email address."),

  websiteLink: Yup.string()
    .required("Please enter the company website link.")
    .trim()
    .url("Please enter valid URL format."),

  address: Yup.string()
    .required("Please enter the company address.")
    .trim()
    .min(2, "Company address cannot be less than 2 characters."),

  address2: Yup.string().optional().trim(),

  state: Yup.string()
    // .optional()
    .required("Please select state.")
    .trim(),

  employee: Yup.string().required("Please enter number of employees.").trim(),

  phoneNumber: Yup.string()
    .test("isValidPhoneNumber", "Invalid phone number", function (value) {
      if (!value || !this.options.context.countryCode) return true;
      const { countryCode } = this.options.context;
      let value1;
      if (value.startsWith(countryCode)) {
        value1 = value.slice(countryCode.length);
      } else {
        return false;
      }
      return true;
    })
    .required("Please enter phone number."),

  landline: Yup.string()
    .test("isValidPhoneNumber", "Invalid phone number", function (value) {
      if (!value || !this.options.context.countryCode) return true;
      const { countryCode } = this.options.context;
      let value1;
      if (value.startsWith(countryCode)) {
        value1 = value.slice(countryCode.length);
      } else {
        return false;
      }
      return true;
    })
    .trim()
    .optional(),

  zip: Yup.string()
    .required("Please enter zip code.")
    .matches(zipRegex, "Please enter valid zip code.")
    // .optional()
    .trim(),

  city: Yup.string()
    // .optional()
    .required("Please select city.")
    .trim(),

  country: Yup.string()
    // .optional()
    .required("Please select country.")
    .trim(),

  workingHours: Yup.string().required("Please enter working hours.").trim(),

  facebook: Yup.string().optional().trim().url("Invalid URL format"),

  twitter: Yup.string().optional().trim().url("Invalid URL format"),

  linkedIn: Yup.string().optional().trim().url("Invalid URL format"),

  instagram: Yup.string().optional().trim().url("Invalid URL format"),

  description: Yup.string().optional().trim(),
});

export const addEditTenureSchema = Yup.object({
  minAmount: Yup.number()
    .required("Min amount is required")
    .positive("Min amount must be positive"),
  maxAmount: Yup.number()
    .required("Max amount is required")
    .positive("Max amount must be positive"),
  durationYears: Yup.string().required("Duration in years is required"),
  durationMonths: Yup.string().required("Duration in months is required"),
  interestRate: Yup.number()
    .required("Interest rate is required")
    .positive("Interest rate must be positive"),
  prematureInterestRate: Yup.number()
    .required("Premature interest rate is required")
    .positive("Premature interest rate must be positive"),
  compounding: Yup.string().required("Compounding selection is required"),
  compoundingPeriod: Yup.string().test(
    'compounding-period-required',
    'Compounding period is required',
    function(value) {
      const { compounding } = this.parent;
      if (compounding === 'Yes' && !value) {
        return false; // validation fails
      }
      return true; // validation passes
    }
  )

  
});

export const servivefeeSchema = Yup.object({
  servicefee : Yup.string().required("Please enter service fee.")
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
        .required('Please enter the old password.')
        .trim()
        .min(8,'Must be more than 8 characters.')
        .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
        .max(16,'Password cannot be more than 16 characters.'),

  newPassword: Yup.string()
        .required('Please enter the new password.')
        .trim()
        .min(8,'Must be more than 8 characters.')
        .matches(passwordRegex, 'Password must be combination of lowercase, uppercase, digit and special character.')
        .max(16,'Password cannot be more than 16 characters.')
        .test(
          "not-same-as-old-password",
          "New password must be different from the old password",
          function (value) {
              return value !== this.parent.oldPassword;
          }
      ),

  confirmPassword: Yup.string()
      .required("Please enter the confirn password.")
      .trim()
      .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const editAccountSchema = Yup.object({
  firstName: Yup.string()
      .required('Please enter your first name.')
      .trim()
      .min(2,'First name cannot be less than 2 characters.')
      .max(20,'First name cannot be more than 20 characters.')
      .matches(firstnameRegex, 'Invalid first name.'),

  lastName: Yup.string()
      .required('Please enter your last name.')
      .trim()
      .min(2,'Last name cannot be less than 2 characters.')
      .max(20,'Last name cannot be more than 20 characters.')
      .matches(lastRegex, 'Invalid last name.'),
      
  email: Yup.string()
      .required("Please enter the email address.")
      .trim()
      .matches(emailRegex, "Please enter valid email address.")
      .max(64, "Email cannot be more than 64 characters.")
      .email("Invalid email address."),
});
